<style scoped>

</style>

<template>
  <div class="row mx-0 align-items-center back-col-9">
    <div class="col-lg-8 col-12 px-0">
      <div class="back-white py-1">
        <date_picker class="date_picker_app left_0  mx-auto" v-model="dates"></date_picker>
      </div>
    </div>
    <div class="col-lg-4 col-12 px-0">
      <div class="orange_btn color-white font-size-12 p-2 w-100 text-center">
        <button class="inner_btn" @click="make_request">
          {{ $t("check_availability") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import date_picker from './base'

export default {
  name: "SolutionPicker",
  components: {
    date_picker
  },
  data() {
    return {
      dates: {
        checkin: null,
        checkout: null
      }
    }
  },
  methods: {
    make_request() {
      this.$root.$emit('submit', this.dates);
    }
  }
}
</script>

