import Vue from 'vue';
import VueI18n from 'vue-i18n'
import translator_generator from '../utils/i18n.js.erb'
import HeaderPicker from "../datepicker/HeaderPicker";
import MenuPicker from "../datepicker/MenuPicker";
import {setAxiosCSRF} from "../booking/helpers";
import _ from 'lodash';
import SolutionPicker from "../datepicker/SolutionPicker";

const axios = setAxiosCSRF();

Vue.use(VueI18n);

document.addEventListener('DOMContentLoaded', () => {

  const contenitore_data = document.getElementById('date_picker_booking_redirect');

  const redirect_url = contenitore_data.attributes["data-redirect-url"];
  if (redirect_url.value == '') {
    console.warn("ATTENZIONE, redirect_url non configurato")
  }

  const send_booking_data = (datas) => {
    axios.post(Routes.search_bookings_solutions_path({format: "json", locale: window.current_locale}), {
      make_reset: true,
      solution_search: {
        data_range: datas
      }
    }).then(() => {
      if (window.location.href === `${window.location.origin}${redirect_url.value}`) {
        window.location.reload();
      } else {
        window.location.href = redirect_url.value;
      }
    });
  }


  _.forEach({
    date_picker_app_listino: SolutionPicker
  }, function (value, key) {
    if (document.getElementById(key)) {
      let picker = new Vue({
        el: `#${key}`,
        render: h => h(value),
        i18n: translator_generator(VueI18n, window.current_locale)
      })
      picker.$on('submit', send_booking_data)
    }
  });


})