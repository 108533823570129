<style scoped>

</style>

<template>
  <div>
    <div class="back-white py-1">
      <!--      <div class="date_picker_app  mx-auto" id="date_picker_app_header"></div>-->
      <date_picker class="date_picker_app  mx-auto" v-model="dates"></date_picker>
    </div>
    <div class="orange_btn color-white font-size-12 p-2 w-100 text-center">
      <button class="inner_btn" @click="make_request">
        {{ $t("check_availability") }}
      </button>
    </div>
  </div>
</template>

<script>

import date_picker from './base'

export default {
  name: "HeaderPicker",
  components: {
    date_picker
  },
  data() {
    return {
      dates: {
        checkin: null,
        checkout: null
      }
    }
  },
  methods: {
    make_request() {
      this.$root.$emit('submit', this.dates);
    }
  }
}
</script>

